import { Accordion, AccordionSummary, accordionSummaryClasses, styled } from '@mui/material';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.white.main,
    boxShadow: 'none',
    '&:before': {
        display: 'none',
    },
    '&:not(:last-child):after': {
        content: '""',
        position: 'absolute',
        width: '25%',
        height: '1px',
        backgroundColor: theme.palette.lightDivider,
        margin: '0 auto',
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

export default StyledAccordion;

export const StyledAccordionSummary = styled(AccordionSummary)<{
    alignment: { [key: string]: string };
}>(({ theme, alignment }) => ({
    padding: `${theme.spacing(2)} 0`,
    [`& .${accordionSummaryClasses.content}`]: {
        ...alignment,
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
        color: theme.palette.white.main,
    },
}));
