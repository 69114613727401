import { createTheme, PaletteColor, SimplePaletteColorOptions } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

export type RightsyColorsTypes =
    | 'primary'
    | 'secondary'
    | 'darkBlue'
    | 'aquaBlue'
    | 'skyBlue'
    | 'green'
    | 'yellow'
    | 'white';

const rightsyColors = {
    darkBlue: createColor('#112a42'),
    aquaBlue: createColor('#0d506b'),
    skyBlue: createColor('#4fa3bf'),
    green: {
        ...createColor('#68d4a1'),
        contrastText: '#112a42',
    },
    yellow: {
        ...createColor('#f4c944'),
        contrastText: '#112a42',
    },
    white: {
        ...createColor('#ffffff'),
        light: '#ffffff80',
    },
};

declare module '@mui/material/styles' {
    interface Palette {
        darkBlue: PaletteColor;
        aquaBlue: PaletteColor;
        skyBlue: PaletteColor;
        green: PaletteColor;
        yellow: PaletteColor;
        white: PaletteColor;
        lightDivider: string;
    }
    interface PaletteOptions {
        darkBlue: SimplePaletteColorOptions;
        aquaBlue: SimplePaletteColorOptions;
        skyBlue: SimplePaletteColorOptions;
        green: SimplePaletteColorOptions;
        yellow: SimplePaletteColorOptions;
        white: SimplePaletteColorOptions;
        lightDivider: string;
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        darkBlue: true;
        aquaBlue: true;
        skyBlue: true;
        green: true;
        yellow: true;
        white: true;
    }
}

const theme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
    palette: {
        mode: 'light',
        primary: rightsyColors.darkBlue,
        secondary: rightsyColors.yellow,
        ...rightsyColors,
        text: {
            primary: rightsyColors.darkBlue.main,
        },
        lightDivider: rightsyColors.skyBlue.light,
    },
    typography: {
        fontFamily: [
            'ITCAvantGardeStd',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export default theme;
