import { Box, BoxProps, styled } from '@mui/material';
import { RightsyColorsTypes } from '../../../assets/theme';
import { SKEW_DEGREES } from '../../../services/constants';

const BackgroundBox = styled(Box)<
    BoxProps & { backgroundColor: RightsyColorsTypes; windowwidth: number }
>(({ theme, backgroundColor, windowwidth }) => ({
    position: 'relative',
    color: theme.palette[backgroundColor].contrastText,

    '&.section:only-of-type': {
        background: theme.palette[backgroundColor].main,
    },
    '&.section:not(:only-of-type)': {
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '0',
            right: '0',
            left: '0',
            bottom: '0',
            background: theme.palette[backgroundColor].main,
            transform: `skewY(-${SKEW_DEGREES}deg)`,
        },
        '&:first-of-type:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: '0',
            left: '0',
            background: theme.palette[backgroundColor].main,

            height: `${windowwidth * 2}px`,
        },
        '&:last-of-type:after': {
            content: '""',
            position: 'absolute',
            right: '0',
            left: '0',
            background: theme.palette[backgroundColor].main,

            bottom: `-${windowwidth}px`,
            height: `${windowwidth * 2}px`,
        },
    },
}));

export default BackgroundBox;
