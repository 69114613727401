import React from 'react';
import { Box, styled } from '@mui/material';
import { SKEW_DEGREES } from '../../services/constants';
import { RightsyColorsTypes } from '../../assets/theme';

const Wrapper = styled(Box)({
    display: 'inline-flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',

    '& > span + *': {
        position: 'relative',
    },
});

const Skew = (props: { bgColor: RightsyColorsTypes; children: React.ReactNode }) => (
    <Wrapper px={2} component='span'>
        <Box
            component='span'
            sx={{
                backgroundColor: `${props.bgColor}.main` || 'primary.main',
                width: '100%',
                height: '50%',
                transform: `skewY(-${SKEW_DEGREES}deg)`,
                position: 'absolute',
            }}
        />
        {props.children}
    </Wrapper>
);

export default Skew;
