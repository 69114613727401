import { Typography } from '@mui/material';
import React from 'react';
import LocalizedText from '../../../components/LocalizedText';
import Section from '../../../components/Section';
import CenterContentSection from '../../../components/Section/CenterContentSection';
import UnderlinedText from '../../../components/UnderlinedText';

const data = {
    label: (
        <Typography variant='h3' align='center' fontWeight='bold'>
            <UnderlinedText skew bgColor='white'>
                <LocalizedText id='SORRY' />
            </UnderlinedText>
        </Typography>
    ),
    description: (
        <React.Fragment>
            <Typography variant='h5'>
                <LocalizedText id='FAILED_TO_RETRIEVE_VALUATION' />
            </Typography>
            <Typography variant='body1'>
                <LocalizedText id='VERIFY_ID_IS_CORRECT' />
            </Typography>
        </React.Fragment>
    ),
};

const Error = () => (
    <Section backgroundColor='green'>
        <CenterContentSection data={[data]} />
    </Section>
);

export default Error;
