const HubspotScheduler = () => (
    <iframe
        title={'hubspot'}
        style={{
            width: '100%',
            height: '700px',
            border: 'none',
        }}
        src={'https://meetings.hubspot.com/charlie-austen/rightsy?embed=true'}
    />
);

export default HubspotScheduler;
