export const SKEW_DEGREES = 2;
export const VALUATION_URL_INPUT_FIELD_ID = 'url-input-form';

export const ROUTES = {
    HOMEPAGE: '/',
    PROFILE_DETAILS: '/profile-details',
    VALUATION: '/valuation/:id',
    VALUATION_SHORT: '/v/:id',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    NOT_FOUND: '*',
};
