import { Box, Grid } from '@mui/material';
import React from 'react';
import { CenterContentSectionTypes } from './types';

const CenterContentSection = ({ data }: { data: CenterContentSectionTypes[] }) => {
    if (!data || data.length <= 0) return null;
    return (
        <React.Fragment>
            {data.map(({ label, description }, ix) => (
                <React.Fragment key={ix}>
                    {label && <Box textAlign='center'>{label}</Box>}
                    {description && (
                        <Box pt={label ? 6 : 0} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item sm md={2} />
                                <Grid item xs={12} sm={10} md={8}>
                                    {description}
                                </Grid>
                                <Grid item sm md={2} />
                            </Grid>
                        </Box>
                    )}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

export default CenterContentSection;
