import { Box, Paper, useMediaQuery, Theme } from '@mui/material';

import SubmitButton from './components/SubmitButton';
import InputField from './components/InputField';

const HeaderValuationForm = () => {
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Paper elevation={3} sx={{ my: 1, mx: 4 }}>
            {isDesktop && (
                <Box
                    py={1}
                    px={2}
                    display='flex'
                    sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Box mr={{ md: 2, lg: 3 }} textAlign='left'>
                        <InputField isHeader />
                    </Box>
                    <SubmitButton />
                </Box>
            )}
        </Paper>
    );
};

export default HeaderValuationForm;
