import { Box, Grid, Paper, Typography } from '@mui/material';
import Hero from '../../../components/Hero';
import LocalizedText from '../../../components/LocalizedText';
import Section from '../../../components/Section';
import UnderlinedText from '../../../components/UnderlinedText';
import { SectionValuationForm } from '../../../components/URLValuationInputField';

const FormValuationSection = () => (
    <Section id='form-valuation-section' backgroundColor='yellow'>
        <Grid container spacing={2}>
            <Grid item sm={1} />
            <Grid item sm={10}>
                <Box pb={8}>
                    <Hero>
                        <LocalizedText id='HOMEPAGE_HEADER' />
                    </Hero>
                </Box>
            </Grid>
            <Grid item md={2} />
            <Grid item xs={12} md={8}>
                <Paper elevation={3} sx={{ padding: { xs: 2, sm: 4, md: 7 }, textAlign: 'center' }}>
                    <UnderlinedText skew bgColor='green'>
                        <Typography
                            position='relative'
                            align='center'
                            variant='h5'
                            textTransform='uppercase'
                            fontWeight='bold'>
                            <LocalizedText id={'VALUATION_FORM_HEADER'} />
                        </Typography>
                    </UnderlinedText>

                    <SectionValuationForm />
                </Paper>
            </Grid>
            <Grid item md={2} />
        </Grid>
    </Section>
);

export default FormValuationSection;
