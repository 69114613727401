import { Box } from '@mui/material';
import Accordion from '../../../components/Accordion';
import LocalizedText from '../../../components/LocalizedText';
import Section from '../../../components/Section';
import Paragraph from '../styledComponents/Paragraph';
import Licensing from './Licensing';
import Perpetuity from './Perpetuity';

const data = [
    {
        label: <LocalizedText id='TC_LICENSING_LABEL' />,
        description: <Licensing />,
    },
    {
        label: <LocalizedText id='TC_PERPETUITY_LABEL' />,
        description: <Perpetuity />,
    },
];

const TCs = () => (
    <Section backgroundColor='primary'>
        <Box pl={2} pt={2}>
            <Paragraph
                id='TERMS_AND_CONDITIONS'
                fontWeight='bold'
                variant='h3'
                textAlign='center'
            />
        </Box>
        <Accordion dataArray={data} labelPosition='center'></Accordion>
    </Section>
);

export default TCs;
