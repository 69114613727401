function getLang() {
    if (navigator.languages != undefined) return navigator.languages[0];
    return navigator.language;
}
export function formatNumber(n: string | number | null) {
    const value = n as number;
    if (n && !Number.isInteger(n)) {
        // TO DO
    }

    return new Intl.NumberFormat(getLang()).format(value);
}
