import { Box, styled } from '@mui/material';

const StyledFloatingButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    right: 0,
    left: 0,
    bottom: theme.spacing(2),
    zIndex: 1,
    pointerEvents: 'none',

    '& > button': {
        pointerEvents: 'all',
    },
}));

export default StyledFloatingButton;
