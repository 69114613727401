import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function getScrollPosition(element: Element) {
    const headerElement = document.getElementById('header') || '';
    const headerHeight = headerElement ? headerElement.getBoundingClientRect().height : 0;
    const extraSpace = 30; // just to leave some clean space between the element and the header or window's border

    const elementPosition = element.getBoundingClientRect().top;
    return elementPosition + window.pageYOffset - headerHeight - extraSpace;
}

export function smoothScroll() {
    const location = useLocation();
    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            // console.log(anchor);
            anchor.addEventListener('click', function (e) {
                const anchorRef = anchor.getAttribute('href') || '';

                // This is just so we can safely have anchor ids that are either numbers or strings
                const anchorID = anchorRef.split('#')[1];
                const element = document.querySelector(`#${CSS.escape(anchorID)}`);

                if (element) {
                    e.preventDefault();
                    const offsetPosition = getScrollPosition(element);

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }
            });
        });
    }, [location]);
    return null;
}

export function scrollToTop() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
}
