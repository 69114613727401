import fetchArtistDetails from '../../pages/Homepage/data/mutations';
import getLocalizedText from '../../services/localization';
import { validateURL } from '../../services/urlValidation';

export default async function submitData(
    input: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dictionary: {
        [key: string]: string;
    },
) {
    if (input != '') {
        await validateURL(input).catch(error => {
            return Promise.reject(getLocalizedText(error, dictionary));
        });

        return await fetchArtistDetails(input)
            .then((response: { [key: string]: string | number | boolean }) => {
                localStorage.setItem('artist_details', JSON.stringify(response));
                return response.meet_threshold as boolean;
            })
            .catch(errorMessage => Promise.reject(getLocalizedText(errorMessage, dictionary)));
    }
}
