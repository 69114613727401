import React from 'react';
import { RightsyColorsTypes } from '../../assets/theme';
import Skew from './Skew';
import Straight from './Straight';

const UnderlinedText = ({
    skew = false,
    bgColor,
    children,
}: {
    skew?: boolean;
    bgColor: RightsyColorsTypes;
    children: React.ReactNode;
}) => {
    if (children) {
        if (skew) return <Skew bgColor={bgColor}>{children}</Skew>;
        return <Straight bgColor={bgColor}>{children}</Straight>;
    }
    return null;
};

export default UnderlinedText;
