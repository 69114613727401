import { ThemeProvider } from '@mui/material';
import theme from '../../assets/theme';
import Header from '../Header';
import Footer from '../Footer';

import { BrowserRouter } from 'react-router-dom';

import { LanguageProvider } from '../../context/Languages';
import { URLValuationFormProvider } from '../../context/URLValuationForm';
import FooterFloatingCTAButton from '../FooterFloatingCTAButton';
import Routes from './Routes';

const App = () => (
    <BrowserRouter>
        <LanguageProvider>
            <URLValuationFormProvider>
                <ThemeProvider theme={theme}>
                    <Header />
                    <Routes />
                    <FooterFloatingCTAButton />
                    <Footer />
                </ThemeProvider>
            </URLValuationFormProvider>
        </LanguageProvider>
    </BrowserRouter>
);

export default App;
