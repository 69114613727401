import { useState, useEffect } from 'react';
import { VALUATION_URL_INPUT_FIELD_ID } from '../../services/constants';

function getInputFieldPosition() {
    const inputField = document.getElementById(VALUATION_URL_INPUT_FIELD_ID);
    if (inputField) {
        return inputField.getBoundingClientRect().top + document.documentElement.scrollTop;
    }
}

export default function useScroll() {
    const [displayForm, setDisplayForm] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const inputFieldPositionY = getInputFieldPosition();
            if (inputFieldPositionY) {
                setDisplayForm(window.scrollY > inputFieldPositionY + window.innerHeight / 2);
            }
        }

        window.addEventListener('scroll', handleScroll, false);
        handleScroll();
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return displayForm;
}
