import { Typography, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import carouselDataTypes from '../../pages/Homepage/sections/UnlockCashSection/types';
import StyledAccordion, { StyledAccordionSummary } from './styledComponents/StyledAccordion';
import { useState } from 'react';

const Accordion = ({
    dataArray,
    labelPosition,
}: {
    dataArray: carouselDataTypes;
    labelPosition?: 'center' | 'left' | 'right';
}) => {
    const [expandedIndex, setExpandedIndex] = useState<undefined | number>(undefined);

    function getColor(ix: number) {
        if (expandedIndex === ix) {
            return 'green.main';
        }
        return 'white.main';
    }

    function getAlignment() {
        const options: { [key: string]: { [key: string]: string } } = {
            left: { justifyContent: 'flex-start' },
            center: { justifyContent: 'center', flexGrow: 'initial' },
            right: { justifyContent: 'flex-end' },
        };

        return labelPosition ? options[labelPosition] : options['left'];
    }

    if (!dataArray || dataArray.length <= 0) return null;

    return (
        <div>
            {dataArray.map((option, ix) => (
                <StyledAccordion
                    key={ix}
                    disableGutters
                    square
                    expanded={ix === expandedIndex}
                    onChange={(e, expanded) => setExpandedIndex(expanded ? ix : undefined)}
                    sx={expandedIndex !== ix ? { opacity: '.75' } : {}}>
                    <StyledAccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`panel-${ix}-content`}
                        id={`panel-${ix}-header`}
                        alignment={getAlignment()}>
                        <Typography
                            variant='body1'
                            fontWeight={expandedIndex == ix ? 'bold' : 'normal'}
                            textTransform='uppercase'
                            color={() => getColor(ix)}
                            sx={theme => ({
                                transition: `color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeIn}`,
                            })}>
                            {option.label}
                        </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>{option.description}</AccordionDetails>
                </StyledAccordion>
            ))}
        </div>
    );
};

export default Accordion;
