import React from 'react';
import UnlockCashSection from './sections/UnlockCashSection';
import FormValuationSection from './sections/FormValuationSection';
import ThreeSimpleStepsSection from './sections/ThreeSimpleStepsSection';
import WhyUnlockCashSection from './sections/WhyUnlockCashSection';
import QuickAndEasyServiceSection from './sections/QuickAndEasyService';

const Homepage = () => (
    <React.Fragment>
        <FormValuationSection />
        <ThreeSimpleStepsSection />
        <UnlockCashSection />
        <WhyUnlockCashSection />
        <QuickAndEasyServiceSection />
    </React.Fragment>
);

export default Homepage;
