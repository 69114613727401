import { Box, Grid, StepLabel, Theme, Typography, useMediaQuery } from '@mui/material';

import LocalizedText from '../../../../components/LocalizedText';
import Section from '../../../../components/Section';
import UnderlinedText from '../../../../components/UnderlinedText';
import React from 'react';
import stepsData from './data';
import Stepper from './styledComponents/Stepper';
import StepConnector from './styledComponents/StepConnector';
import Step from './styledComponents/Step';
import StepIcon from './styledComponents/StepIcon';

const ThreeSimpleStepsSection = () => {
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Section backgroundColor='green'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box mb={4}>
                        <Typography
                            position='relative'
                            align='center'
                            variant='h5'
                            textTransform='uppercase'
                            fontWeight='bold'>
                            <LocalizedText id='THREE_SIMPLE_STEPS_SECTION_HEADER' />
                        </Typography>
                    </Box>
                    {isDesktop && (
                        <Stepper
                            alternativeLabel
                            activeStep={stepsData.length}
                            connector={<StepConnector />}>
                            {stepsData.map((step, index) => (
                                <Step key={index} isdesktop={isDesktop.toString()}>
                                    <StepLabel StepIconComponent={StepIcon}>
                                        <Box pb={2}>
                                            <UnderlinedText skew bgColor='yellow'>
                                                <Typography
                                                    position='relative'
                                                    textTransform='uppercase'
                                                    fontWeight='bold'>
                                                    {step.label}
                                                </Typography>
                                            </UnderlinedText>
                                        </Box>
                                        <Box px={[0, 1, 8]}>
                                            <Typography variant='body2' color='white.main'>
                                                {step.description}
                                            </Typography>
                                        </Box>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )}
                </Grid>
                {!isDesktop && (
                    <React.Fragment>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                            <Stepper
                                activeStep={3}
                                orientation='vertical'
                                connector={<StepConnector />}>
                                {stepsData.map((step, index) => (
                                    <Step key={index} isdesktop={isDesktop.toString()}>
                                        <StepLabel StepIconComponent={StepIcon}>
                                            <Box pb={2}>
                                                <UnderlinedText skew bgColor='yellow'>
                                                    <Typography
                                                        position='relative'
                                                        textTransform='uppercase'
                                                        fontWeight='bold'>
                                                        {step.label}
                                                    </Typography>
                                                </UnderlinedText>
                                            </Box>
                                            <Box>
                                                <Typography variant='body2' color='white.main'>
                                                    {step.description}
                                                </Typography>
                                            </Box>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Section>
    );
};

export default ThreeSimpleStepsSection;
