import { Box, styled } from '@mui/material';
import React from 'react';
import { RightsyColorsTypes } from '../../assets/theme';

const Wrapper = styled(Box)<{ underlinecolor: RightsyColorsTypes }>(
    ({ theme, underlinecolor }) => ({
        display: 'inline',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',

        '& > *': {
            display: 'inline',
            boxShadow: `inset 0 -0.4em 0 0 ${theme.palette[underlinecolor].main}`,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    }),
);

const Straight = (props: { bgColor: RightsyColorsTypes; children: React.ReactNode }) => (
    <Wrapper component='span' underlinecolor={props.bgColor || 'primary'}>
        {props.children}
    </Wrapper>
);

export default Straight;
