import { Grid, Fade, Box, Typography } from '@mui/material';
import LocalizedText from '../../../components/LocalizedText';
import Section from '../../../components/Section';
import CenterContentSection from '../../../components/Section/CenterContentSection';
import UnderlinedText from '../../../components/UnderlinedText';
import HubspotScheduler from '../hubspotScheduler';

const data = {
    label: (
        <Typography align='center' variant='body1' textTransform='uppercase' fontWeight='bold'>
            <UnderlinedText bgColor='skyBlue'>
                <LocalizedText id='ENSURE_YOU_HAVE_THE_INFORMATION' />
            </UnderlinedText>
        </Typography>
    ),
    description: (
        <Typography variant='body1' align='left'>
            <LocalizedText id='ENSURE_YOU_HAVE_THE_INFORMATION_LIST' />
        </Typography>
    ),
};

const Scheduler = ({ loading }: { loading: boolean }) => (
    <Section backgroundColor='darkBlue'>
        <CenterContentSection data={[data]} />
        <Grid container spacing={2} mt={4}>
            <Grid item xs={12} sx={{ height: '700px' }}>
                <Fade in={!loading} unmountOnExit>
                    <Box>
                        <HubspotScheduler />
                    </Box>
                </Fade>
            </Grid>
        </Grid>
    </Section>
);

export default Scheduler;
