import LocalizedText from '../../components/LocalizedText';

const inputFields = [
    {
        key: 'firstname',
        label: <LocalizedText id='FIRSTNAME_FORM_INPUT' />,
    },
    {
        key: 'lastname',
        label: <LocalizedText id='LASTNAME_FORM_INPUT' />,
    },
    {
        key: 'email',
        label: <LocalizedText id='EMAIL_FORM_INPUT' />,
    },
    {
        key: 'mobilephone',
        label: <LocalizedText id='MOBILEPHONE_FORM_INPUT' />,
    },
    {
        key: 'city',
        label: <LocalizedText id='CITY_FORM_INPUT' />,
    },
];

export default inputFields;
