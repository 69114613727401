import { Grid, Paper } from '@mui/material';
import Section from '../../components/Section';
import Sidebar from './components/Sidebar';

import Form from './components/Form';

const ProfileDetails = () => (
    <Section backgroundColor='primary'>
        <Grid container spacing={2}>
            <Grid item container xs={12} md={4} spacing={2} alignContent='flex-start'>
                <Sidebar />
            </Grid>
            <Grid item xs={12} md={8} lg={7} textAlign='center'>
                <Paper
                    elevation={3}
                    sx={{
                        py: 7,
                        px: 2,
                        textAlign: 'center',
                    }}>
                    <Form />
                </Paper>
            </Grid>
        </Grid>
    </Section>
);

export default ProfileDetails;
