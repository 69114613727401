import React from 'react';
import Hero from '../../components/Hero';
import LocalizedText from '../../components/LocalizedText';
import Section from '../../components/Section';
import CenterContentSection from '../../components/Section/CenterContentSection';

const data = {
    description: (
        <Hero bgColor='green' shadowColor='yellow'>
            <LocalizedText id='404' />
        </Hero>
    ),
};

const NotFound = () => (
    <React.Fragment>
        <Section backgroundColor='green'>
            <CenterContentSection data={[data]} />
        </Section>
    </React.Fragment>
);

export default NotFound;
