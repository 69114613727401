import { TypographyProps, Box, Typography } from '@mui/material';
import LocalizedText from '../../../components/LocalizedText';

const Paragraph = (props: TypographyProps & { id: string }) => {
    const { id, ...typographyProps } = props;
    return (
        <Box my={2}>
            <Typography variant='body1' {...typographyProps}>
                <LocalizedText id={id} />
            </Typography>
        </Box>
    );
};

export default Paragraph;
