import { Typography } from '@mui/material';
import LocalizedText from '../../../../components/LocalizedText';
import { CenterContentSectionTypes } from '../../../../components/Section/types';
import UnderlinedText from '../../../../components/UnderlinedText';
import carouselDataTypes from './types';

export const carouselData: carouselDataTypes = [
    {
        label: <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_01_TITLE' />,
        description: (
            <Typography variant='body1'>
                <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_01_DESCRIPTION' />
            </Typography>
        ),
    },
    {
        label: <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_02_TITLE' />,
        description: (
            <Typography variant='body1'>
                <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_02_DESCRIPTION' />
            </Typography>
        ),
    },
    {
        label: <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_03_TITLE' />,
        description: (
            <Typography variant='body1'>
                <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_03_DESCRIPTION' />
            </Typography>
        ),
    },
    {
        label: <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_04_TITLE' />,
        description: (
            <Typography variant='body1'>
                <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_04_DESCRIPTION' />
            </Typography>
        ),
    },
    {
        label: <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_05_TITLE' />,
        description: (
            <Typography variant='body1'>
                <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_05_DESCRIPTION' />
            </Typography>
        ),
    },
    {
        label: <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_06_TITLE' />,
        description: (
            <Typography variant='body1'>
                <LocalizedText id='UNLOCK_CASH_SECTION_CAROUSEL_06_DESCRIPTION' />
            </Typography>
        ),
    },
];

export const sectionData: CenterContentSectionTypes = {
    label: (
        <Typography
            position='relative'
            align='center'
            variant='h5'
            textTransform='uppercase'
            fontWeight='bold'>
            <UnderlinedText bgColor='green'>
                <LocalizedText id='UNLOCK_CASH_SECTION_HEADER' />
            </UnderlinedText>
        </Typography>
    ),
    description: (
        <Typography position='relative' align='center' variant='body1'>
            <LocalizedText id='UNLOCK_CASH_SECTION_DESCRIPTION' />
        </Typography>
    ),
};
