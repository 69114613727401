import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import Hero from '../../components/Hero';
import Img from '../../components/Img';
import LocalizedText from '../../components/LocalizedText';
import Section from '../../components/Section';

import fetchArtistValuation from './data/queries';

import { formatNumber } from '../../services/formatting';

import ValuationArtistDetailsTypes from './types';
import CenterContentSection from '../../components/Section/CenterContentSection';
import Scheduler from './sections/Scheduler';
import Assumptions from './sections/Assumptions';
import Error from './sections/Error';


const Valuation = () => {
    const [loading, setLoading] = useState(true);
    const [failed, setFailed] = useState(false);
    const [details, setDetails] = useState<ValuationArtistDetailsTypes>();

    const { id } = useParams();

    useEffect(() => {
        fetchArtistValuation(id as string)
            .then(res => {
                setDetails(res);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setFailed(true);
            });
    }, []);

    return (
        <React.Fragment>
            {failed ? (
                <Error />
            ) : (
                <React.Fragment>
                    <Section backgroundColor='green'>
                        {loading ? (
                            <CenterContentSection data={[{ description: <CircularProgress /> }]} />
                        ) : (
                            <Grid container spacing={2}>
                                {details && Object.keys(details).length ? (
                                    <React.Fragment>
                                        <Grid item sm={3} />
                                        <Grid item sm={2}>
                                            {details.artist.image_url && (
                                                <Img
                                                    role='profile-img'
                                                    maxWidth={146}
                                                    src={details.artist.image_url as string}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item sm={4}>
                                            <React.Fragment>
                                                {details.artist.name && (
                                                    <Typography
                                                        variant='h5'
                                                        fontWeight='bold'
                                                        textTransform='uppercase'>
                                                        {details.artist.name}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    fontWeight='bold'
                                                    textTransform='uppercase'>
                                                    <LocalizedText id='YOUR_VALUATION_IS' />
                                                </Typography>
                                                {details.valuation_amount && (
                                                    <Hero
                                                        align='left'
                                                        variant='h3'
                                                        bgColor='green'
                                                        shadowColor='yellow'>
                                                        £{formatNumber(details.valuation_amount)}
                                                    </Hero>
                                                )}
                                                <Box>
                                                    <Typography variant='caption'>
                                                        <LocalizedText id='FOUR_DAYS_GUARANTEED' />
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='caption'>
                                                        <LocalizedText id='CHECK_ASSUMPTIONS' />
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='caption'>
                                                        <LocalizedText id='NOT_PUBLISHING' />
                                                    </Typography>
                                                </Box>
                                            </React.Fragment>
                                        </Grid>
                                        <Grid item sm={3} />
                                    </React.Fragment>
                                ) : (
                                    <Typography>
                                        <LocalizedText id='NO_DATA' />
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </Section>

                    <Scheduler loading={loading} />

                    <Assumptions />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Valuation;
