import { MenuItem, Typography } from '@mui/material';
import { useContext } from 'react';

import { LanguageContext } from '../../context/Languages';
import { languageOptions } from '../../languages';
import StyledSelect from './styledComponents/StyledSelect';

const LanguageSelector = () => {
    const { userLanguage, userLanguageChange } = useContext(LanguageContext);

    // set selected language by calling context method
    const handleLanguageChange = (value: string | unknown) => {
        userLanguageChange(value);
    };

    return (
        <StyledSelect
            labelId='language-selector-label'
            id='language-selector'
            variant='standard'
            value={userLanguage}
            label='Language'
            size='small'
            onChange={e => handleLanguageChange(e.target.value)}>
            {Object.entries(languageOptions).map(([id, name]) => (
                <MenuItem key={id} value={id}>
                    <Typography sx={{ typography: { xs: 'caption', sm: 'body2', md: 'body1' } }}>
                        {name}
                    </Typography>
                </MenuItem>
            ))}
        </StyledSelect>
    );
};

export default LanguageSelector;
