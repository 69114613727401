import useScroll from './hooks';

import { Button, Fade, Theme, useMediaQuery } from '@mui/material';

import StyledFloatingButton from './styledComponents';
import LocalizedText from '../LocalizedText';

const FooterFloatingCTAButton = () => {
    const displayForm = useScroll();

    function handleReposition() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    if (!isDesktop) {
        return (
            <Fade in={displayForm}>
                <StyledFloatingButton>
                    <Button color='yellow' variant='contained' onClick={handleReposition}>
                        <LocalizedText id='CTA_GET_VALUATION' />
                    </Button>
                </StyledFloatingButton>
            </Fade>
        );
    }

    return null;
};

export default FooterFloatingCTAButton;
