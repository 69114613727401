import axios from 'axios';
import ValuationArtistDetailsTypes from '../../types';

const fetchArtistValuation = (id: string) => {
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const endpoint = process.env.REACT_APP_FETCH_ARTIST_VALUATION_ENDPOINT as string;

    const queryUrl = apiUrl + endpoint + `?id=${id}`;

    return axios.get(queryUrl).then(res => res.data as ValuationArtistDetailsTypes);
};
export default fetchArtistValuation;
