import { Box } from '@mui/material';
import Section from '../../../components/Section';
import Paragraph from '../styledComponents/Paragraph';

const PrivacyPolicy = () => (
    <Section backgroundColor='primary'>
        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY' fontWeight='bold' variant='h3' textAlign='center' />
            <Paragraph id='PRIVACY_POLICY_GDPR' textAlign='center' />
            <Paragraph id='PRIVACY_POLICY_SUMMARY_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_SUMMARY_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_INTRODUCTION_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_INTRODUCTION_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_INTRODUCTION_DESCRIPTION_LIST' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_01_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_01_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_01_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_02_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_02_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_03_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_03_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_04_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_04_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_05_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_01_SECTION_05_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_02_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_02_SECTION_01_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_02_SECTION_02_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_02_SECTION_02_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_03_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_03_SECTION_01_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_04_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_01_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_02_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_02_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_03_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_03_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_04_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_04_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_05_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_05_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_06_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_06_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_07_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_04_SECTION_07_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_05_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_05_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_06_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_06_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_07_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_07_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_08_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_08_SECTION_02_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_08_SECTION_02_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_09_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_09_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_09_SECTION_01_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_09_SECTION_01_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_09_SECTION_02_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_09_SECTION_02_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_09_SECTION_03_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_09_SECTION_03_DESCRIPTION' />
        </Box>

        <Box pl={2} pt={2}>
            <Paragraph id='PRIVACY_POLICY_10_LABEL' fontWeight='bold' variant='h5' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_01_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_01_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_02_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_02_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_03_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_03_DESCRIPTION' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_04_LABEL' fontWeight='bold' variant='h6' />
            <Paragraph id='PRIVACY_POLICY_10_SECTION_04_DESCRIPTION' />
        </Box>
        
    </Section>
);

export default PrivacyPolicy;
