import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const URLValuationFormContext = createContext({
    input: '',
    // eslint-disable-next-line
    setInput: value => {},
    fetching: false,
    // eslint-disable-next-line
    toggleFetching: () => {},
    error: undefined,
    // eslint-disable-next-line
    setError: value => {},
    thresholdNotMet: false,
    // eslint-disable-next-line
    setThresholdNotMet: value => {},
});

export function URLValuationFormProvider({ children }) {
    const [input, setInput] = useState('');
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(undefined);
    const [thresholdNotMet, setThresholdNotMet] = useState(undefined);

    const provider = {
        input,
        setInput,
        fetching,
        toggleFetching: () => setFetching(prev => !prev),
        error,
        setError,
        thresholdNotMet,
        setThresholdNotMet,
    };

    return (
        <URLValuationFormContext.Provider value={provider}>
            {children}
        </URLValuationFormContext.Provider>
    );
}

URLValuationFormProvider.propTypes = {
    children: PropTypes.node,
};
