import {
    Grid,
    Typography,
    TextField,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LocalizedText from '../../../components/LocalizedText';
import UnderlinedText from '../../../components/UnderlinedText';
import { ROUTES } from '../../../services/constants';
import inputFields from '../data';
import { MuiTelInput } from 'mui-tel-input';
import React, { FormEvent, useState } from 'react';
import FormValuesTypes from '../types';
import postArtistDetails from '../data/queries';
import validateBeforeSubmit from '../utils';

const Form = () => {
    const storage = localStorage.getItem('artist_details');
    const details = storage
        ? (JSON.parse(storage) as { [key: string]: string | number | null | string[] })
        : {};

    const [formValues, setFormValues] = useState<FormValuesTypes>({ ...details });
    const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({});
    const [storing, setStoring] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleInput = (key: string, value: string | boolean) => {
        setFormValues({
            ...formValues,
            [key]: value,
        });
    };

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setStoring(true);

        validateBeforeSubmit(formValues)
            .then(() => {
                postArtistDetails(formValues).then(() => {
                    localStorage.setItem('artist_details', JSON.stringify(formValues));
                    setSubmitted(!submitted);
                    setStoring(false);
                });
            })
            .catch(errors => {
                setFormErrors(errors);
                setStoring(false);
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container columns={7} spacing={2}>
                <Grid item xs={7}>
                    <UnderlinedText skew bgColor='green'>
                        <Typography
                            position='relative'
                            align='center'
                            variant='h5'
                            textTransform='uppercase'
                            fontWeight='bold'>
                            {!submitted ? (
                                <LocalizedText id='PROFILE_VERIFICATION_FORM_HEADER' />
                            ) : (
                                <LocalizedText id='SUBMITTED' />
                            )}
                        </Typography>
                    </UnderlinedText>
                </Grid>
                {!submitted ? (
                    <React.Fragment>
                        <Grid item md />

                        <Grid item md={5}>
                            {inputFields.map(
                                (
                                    field: {
                                        [key: string]: string | React.ReactNode;
                                    },
                                    i: number,
                                ) => {
                                    if (field.key == 'mobilephone') {
                                        return (
                                            <MuiTelInput
                                                key={field.key + `_${i}`}
                                                required
                                                value={formValues['mobilephone']}
                                                label={field.label}
                                                variant='standard'
                                                fullWidth={true}
                                                sx={{ my: 2 }}
                                                focusOnSelectCountry
                                                forceCallingCode
                                                defaultCountry="US"
                                                error={formErrors['mobilephone']}
                                                onChange={(value: string) =>
                                                    handleInput(field.key as string, value)
                                                }
                                                onFocus={() =>
                                                    setFormErrors({
                                                        ...formErrors,
                                                        mobilephone: false,
                                                    })
                                                }
                                            />
                                        );
                                    }
                                    return (
                                        <TextField
                                            key={field.key + `_${i}`}
                                            required
                                            label={field.label}
                                            variant='standard'
                                            fullWidth={true}
                                            sx={{ my: 2 }}
                                            error={field.key == 'email' && formErrors['email']}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) =>
                                                handleInput(field.key as string, event.target.value)
                                            }
                                            onFocus={() => {
                                                if (field.key == 'email') {
                                                    setFormErrors({
                                                        ...formErrors,
                                                        email: false,
                                                    });
                                                }
                                            }}
                                        />
                                    );
                                },
                            )}

                            <Button
                                type='submit'
                                color='yellow'
                                variant='contained'
                                size='large'
                                sx={{ my: 5 }}
                                disabled={storing}>
                                {storing ? (
                                    <LocalizedText id='SUBMITTING' />
                                ) : (
                                    <LocalizedText id='CTA_GET_VALUATION_FORM' />
                                )}
                            </Button>
                        </Grid>

                        <Grid item md />
                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <Typography variant='body2'>
                                <LocalizedText id='VALUATION_BASES_INFORMATION' />
                            </Typography>
                            <FormGroup sx={{ my: 2, flexDirection: 'initial' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formValues['opt_out'] || false}
                                            onChange={() =>
                                                handleInput(
                                                    'opt_out',
                                                    !formValues['opt_out'] || false,
                                                )
                                            }
                                        />
                                    }
                                    labelPlacement='start'
                                    label={
                                        <Typography variant='body2' textAlign='left'>
                                            <LocalizedText id='OPT_OUT_MAILING_LIST' />
                                        </Typography>
                                    }
                                    sx={{ margin: 0 }}
                                />
                            </FormGroup>

                            <Link
                                component={RouterLink}
                                to={ROUTES.PRIVACY_POLICY}
                                relative='path'
                                underline='always'>
                                <Typography variant='body2' textAlign='left'>
                                    <LocalizedText id='READ_OUR_PRIVACY_POLICY' />
                                </Typography>
                            </Link>
                        </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                            <Typography>
                                <LocalizedText id='VALUATION_POST_SUBMISSION_MESSAGE' />
                            </Typography>
                        </Grid>
                        <Grid item xs={1} />
                    </React.Fragment>
                )}
            </Grid>
        </form>
    );
};

export default Form;
