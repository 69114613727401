import useWindowSize from './hooks';

import { Container } from '@mui/material';

import { SectionTypes } from './types';
import { getSkewPadding, getHeightToFlattenSkew } from './utils';

import BackgroundBox from './styledComponents/BackgroundBox';

const Section = (props: SectionTypes) => {
    const size = useWindowSize();

    const { children, ...sectionProps } = props;

    return (
        <BackgroundBox
            className='section'
            {...sectionProps}
            windowwidth={getHeightToFlattenSkew(size.width)}>
            <Container
                maxWidth={false}
                disableGutters
                sx={theme => ({
                    position: 'relative',
                    height: '100%',
                    padding: `${getSkewPadding(theme.breakpoints.values.lg)}px 0px`,
                })}>
                <Container maxWidth='lg'>{children}</Container>
            </Container>
        </BackgroundBox>
    );
};

export default Section;
