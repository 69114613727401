import axios from 'axios';
import FormValuesTypes from '../../types';

const postArtistDetails = (data: FormValuesTypes) => {
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const endpoint = process.env.REACT_APP_POST_ARTIST_DETAILS_ENDPOINT as string;

    const queryUrl = apiUrl + endpoint;

    // set preferred language on form data
    // eslint-disable-next-line
    data.preferred_language = window.localStorage.getItem('language') ?? 'en';

    return axios.post(queryUrl, data).then(res => res.data);
};
export default postArtistDetails;
