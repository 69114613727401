import { Box, styled } from '@mui/material';

const ArrowPointer = styled(Box)(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderLeft: `17px solid ${theme.palette.primary.main}`,
    borderBottom: '10px solid transparent',
    marginRight: '-15px',
    position: 'relative',

    '&:before': {
        content: '""',
        width: '15px',
        height: '2px',
        backgroundColor: 'white',
        top: '4px',
        right: 0,
        position: 'absolute',
        transform: 'skewY(-32deg)',
    },
    '&:after': {
        content: '""',
        width: '15px',
        height: '2px',
        backgroundColor: 'white',
        top: '-6px',
        right: 0,
        position: 'absolute',
        transform: 'skewY(32deg)',
    },
}));

export default ArrowPointer;
