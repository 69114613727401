import { Box, Grid } from '@mui/material';
import Accordion from '../../../../components/Accordion';
import Carousel from '../../../../components/Carousel';

import Section from '../../../../components/Section';
import CenterContentSection from '../../../../components/Section/CenterContentSection';
import { carouselData, sectionData } from './data';

const UnlockCashSection = () => (
    <Section backgroundColor='primary'>
        <CenterContentSection data={[sectionData]} />
        <Box pt={{ xs: 2, sm: 4, md: 10 }} textAlign='center'>
            <Grid container spacing={2}>
                <Grid item sm />
                <Grid item container md={8} display={{ xs: 'none', md: 'block' }}>
                    <Carousel dataArray={carouselData} />
                </Grid>
                <Grid item sm={8} display={{ xs: 'block', md: 'none' }}>
                    <Accordion dataArray={carouselData} />
                </Grid>
                <Grid item sm />
            </Grid>
        </Box>
    </Section>
);

export default UnlockCashSection;
