import { Box, Button, Fade, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import carouselDataTypes from '../../pages/Homepage/sections/UnlockCashSection/types';
import ArrowPointer from './styledComponents/ArrowPointer';
import OptionsList from './styledComponents/OptionsList';

const Carousel = ({ dataArray }: { dataArray: carouselDataTypes }) => {
    const [checked, setChecked] = useState(true);
    const [count, setCount] = useState(0);
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const fadeTime = 250;
    const visibleTime = 13000;
    const transitionTiming = {
        enter: fadeTime * 1.5,
        exit: fadeTime * 1.5,
    };

    function entered(entered: boolean) {
        if (!entered) {
            count == dataArray.length - 1 ? setCount(0) : setCount(count + 1);
        }
        clearTimeout(timer);
        const timeout = setTimeout(
            () => {
                setChecked(prev => !prev);
            },
            entered ? visibleTime : fadeTime,
        );
        setTimer(timeout);
    }

    function handleClick(position: number) {
        setCount(position);
        entered(true);
    }

    if (!dataArray || dataArray.length <= 0) return null;

    return (
        <Grid item container spacing={2}>
            <React.Fragment>
                <Grid item sm={5}>
                    <OptionsList>
                        {dataArray.map((option, ix) => (
                            <li key={ix}>
                                <Button
                                    id={`carousel-${ix}-label`}
                                    size='small'
                                    onClick={() => handleClick(ix)}
                                    sx={theme => ({ m: theme.spacing(2), textAlign: 'left' })}>
                                    <Typography
                                        variant='body1'
                                        fontWeight={count == ix ? 'bold' : 'normal'}
                                        textTransform='uppercase'
                                        color={count == ix ? 'green.main' : 'white.main'}
                                        sx={{
                                            transition: `color ${
                                                fadeTime * 1.5
                                            }ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                                        }}>
                                        {option.label}
                                    </Typography>
                                </Button>
                                <Fade in={count == ix} timeout={transitionTiming}>
                                    <ArrowPointer component='span' />
                                </Fade>
                            </li>
                        ))}
                    </OptionsList>
                </Grid>
                <Grid item sm={7} display='flex' alignItems='center'>
                    <Box pl={4} id={`carousel-${count}-description`}>
                        <Fade
                            in={checked}
                            timeout={transitionTiming}
                            onExited={() => entered(false)}
                            onEntered={() => entered(true)}>
                            <Box>{dataArray[count].description}</Box>
                        </Fade>
                    </Box>
                </Grid>
            </React.Fragment>
        </Grid>
    );
};

export default Carousel;
