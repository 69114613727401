import React from 'react';
import { Box, Typography } from '@mui/material';
import LocalizedText from '../../../components/LocalizedText';
import UnderlinedText from '../../../components/UnderlinedText';
import StyledList from '../styledComponents/StyledList';
import Accordion from '../../../components/Accordion';

const TopFiveTracksList = ({ data }: { data: string[] }) => {
    if (!data) return null;

    const topFiveTracks = {
        label: (
            <UnderlinedText bgColor='skyBlue'>
                <LocalizedText id='YOUR_TOP_TRACKS' />
            </UnderlinedText>
        ),
        description: (
            <StyledList>
                {data.map((track: string, ix: number) => {
                    return (
                        <li key={track.replaceAll(' ', '') + `_${ix}`}>
                            <Typography
                                variant='body2'
                                textTransform='capitalize'
                                textAlign='left'
                                fontWeight='normal'>
                                {track}
                            </Typography>
                        </li>
                    );
                })}
            </StyledList>
        ),
    };

    return (
        <React.Fragment>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Box my={2}>
                    <Typography variant='body1' textTransform='uppercase' fontWeight='bold'>
                        {topFiveTracks.label}
                    </Typography>
                </Box>
                <Box mx={{ md: 4 }}>{topFiveTracks.description}</Box>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Accordion dataArray={[topFiveTracks]} labelPosition='center' />
            </Box>
        </React.Fragment>
    );
};

export default TopFiveTracksList;
