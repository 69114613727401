import { Box } from '@mui/material';
import React from 'react';
import Paragraph from '../styledComponents/Paragraph';

const Licensing = () => (
    <React.Fragment>
        <Paragraph id='TC_LICENSING_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_01_SECTION_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_LICENSING_01_SECTION_DESCRIPTION' />
        <Paragraph id='TC_LICENSING_01_SECTION_LIST_01' />
        <Paragraph id='TC_LICENSING_01_SECTION_LIST_02' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_02_SECTION_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_LICENSING_02_SECTION_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_03_SECTION_LABEL' fontWeight='bold' variant='h6' />
        </Box>

        <Paragraph id='TC_LICENSING_03_SECTION_DESCRIPTION' />
        <Paragraph id='TC_LICENSING_03_SECTION_AGREEMENT' />
        <Paragraph id='TC_LICENSING_03_SECTION_TABLE' />
        <Paragraph id='TC_LICENSING_03_SECTION_DESCRIPTION_02' />

        <Paragraph id='TC_LICENSING_04_SECTION_LABEL' />
        <Paragraph id='TC_LICENSING_04_SECTION_DESCRIPTION' />
        <Paragraph id='TC_LICENSING_05_SECTION_LABEL' />
        <Paragraph id='TC_LICENSING_05_SECTION_DESCRIPTION' />
        <Paragraph id='TC_LICENSING_06_SECTION_LABEL' />
        <Paragraph id='TC_LICENSING_06_SECTION_DESCRIPTION' />
        <Paragraph id='TC_LICENSING_07_SECTION_LABEL' />
        <Paragraph id='TC_LICENSING_07_SECTION_DESCRIPTION' />
        <Paragraph id='TC_LICENSING_08_SECTION_LABEL' />
        <Paragraph id='TC_LICENSING_08_SECTION_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_09_SECTION_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_LICENSING_09_SECTION_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_10_SECTION_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_LICENSING_10_SECTION_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_11_SECTION_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_LICENSING_11_SECTION_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_12_SECTION_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_LICENSING_12_SECTION_DESCRIPTION' />

        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_SCHEDULE_01' textAlign='center' variant='h5' />
        </Box>
        <Paragraph id='TC_LICENSING_SCHEDULE_01_DESCRIPTION' />

        <Box pl={2} pt={2}>
            <Paragraph id='TC_LICENSING_SCHEDULE_02' textAlign='center' variant='h5' />
        </Box>
        <Paragraph id='TC_LICENSING_SCHEDULE_02_DESCRIPTION' />
    </React.Fragment>
);

export default Licensing;
