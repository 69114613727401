import { styled, Step as MuiStep, StepProps } from '@mui/material';

const Step = styled(MuiStep)<StepProps & { isdesktop: string }>(({ theme, isdesktop }) => {
    if (isdesktop) {
        return {
            '&:not(:last-of-type)': {
                position: 'relative',
                '&:after': {
                    content: '""',
                    width: '2px',
                    height: '4rem',
                    background: theme.palette.white.main,
                    position: 'absolute',
                    top: 'calc(50% + -1rem)',
                    right: 0,
                },
            },
        };
    }
});
export default Step;
