import { styled, StepConnector as MuiStepConnector, stepConnectorClasses } from '@mui/material';

const StepConnector = styled(MuiStepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
        borderWidth: '2px',
        borderColor: theme.palette.white.main,
        minHeight: theme.spacing(6),
    },
}));

export default StepConnector;
