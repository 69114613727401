import { Box, styled } from '@mui/material';
import { RightsyColorsTypes } from '../../assets/theme';

const Img = styled(Box)<{ src: string; lineColor?: RightsyColorsTypes }>(
    ({ theme, src, lineColor }) => ({
        width: '100%',
        margin: '0 auto',
        backgroundColor: theme.palette.grey[500],
        backgroundImage: `url(${src})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxSizing: 'border-box',
        borderRadius: '100%',
        border: `3px solid ${theme.palette[lineColor || 'white'].main}`,

        '&:after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%',
        },
    }),
);

export default Img;
