import logo from '../../assets/img/rightsy-logo.png';
import { Box, Container, Fade } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import LanguageSelector from '../LanguageSelector';
import { useEffect, useState } from 'react';
import { VALUATION_URL_INPUT_FIELD_ID } from '../../services/constants';
import { HeaderValuationForm } from '../URLValuationInputField';
import StyledHeader from './styledComponents/StyledHeader';

const Header = () => {
    const location = useLocation();

    const [isScrolled, setIsScrolled] = useState(false);
    const [displayForm, setDisplayForm] = useState(false);

    useEffect(() => {
        function handleScroll() {
            setIsScrolled(window.scrollY > 20);

            const inputField = document.getElementById(VALUATION_URL_INPUT_FIELD_ID);
            if (inputField) {
                const position =
                    inputField.getBoundingClientRect().top + document.documentElement.scrollTop;

                position && setDisplayForm(window.scrollY > position);
            } else if (displayForm) {
                setDisplayForm(false);
            }
        }
        window.addEventListener('scroll', handleScroll, false);
        handleScroll();
        return () => window.removeEventListener('scroll', handleScroll);
    }, [location]);

    return (
        <StyledHeader id='header' elevation={isScrolled ? 3 : 0}>
            <Container maxWidth='lg'>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box py={1} height={[50, 60, 80]} boxSizing='border-box'>
                        <Link to='/' relative='path'>
                            <img src={logo} height='100%' />
                        </Link>
                    </Box>
                    <Fade in={displayForm} timeout={350} unmountOnExit>
                        <Box>
                            <HeaderValuationForm />
                        </Box>
                    </Fade>
                    <LanguageSelector />
                </Box>
            </Container>
        </StyledHeader>
    );
};

export default Header;
