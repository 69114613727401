import { SKEW_DEGREES } from '../../services/constants';

function getTanFromDegrees() {
    return Math.tan((SKEW_DEGREES * Math.PI) / 180);
}

export function getSkewPadding(width: number) {
    const tan = getTanFromDegrees();
    return tan * (width / 0.4);
}

export function getHeightToFlattenSkew(width: number) {
    const tan = getTanFromDegrees();
    return tan * (width / 2);
}
