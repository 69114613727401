import { Box } from '@mui/material';
import React from 'react';
import Paragraph from '../styledComponents/Paragraph';

const Perpetuity = () => (
    <React.Fragment>
        <Paragraph id='TC_PERPETUITY_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_01_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_01_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_02_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_02_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_03_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_03_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_04_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_04_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_05_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_05_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_06_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_06_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_07_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_07_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph id='TC_PERPETUITY_08_LABEL' fontWeight='bold' variant='h6' />
        </Box>
        <Paragraph id='TC_PERPETUITY_08_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph
                id='TC_PERPETUITY_SCHEDULE_1_LABEL'
                fontWeight='bold'
                textAlign='center'
                variant='h5'
            />
        </Box>
        <Paragraph id='TC_PERPETUITY_SCHEDULE_1_DESCRIPTION' />
        <Box pl={2} pt={2}>
            <Paragraph
                id='TC_PERPETUITY_SCHEDULE_2_LABEL'
                fontWeight='bold'
                textAlign='center'
                variant='h5'
            />
        </Box>
        <Paragraph id='TC_PERPETUITY_SCHEDULE_2_DESCRIPTION' />
    </React.Fragment>
);

export default Perpetuity;
