/**
 * Validate any URL to make sure at least it starts with http(s):// and/or www.
 *
 * @param {string | null} url - Simple string as URL
 *
 * @return {promise} - Resolve/Reject
 * */
export function validateURL(url: string) {
    return new Promise((resolve, reject) => {
        if (!url) reject('Missing url');

        // VALIDATE (http(s)://)open.spotify.com/artist/
        const validateHttpWww = new RegExp(/^((http|https):\/\/)?(open.spotify.com\/artist\/)/);

        if (!validateHttpWww.test(url)) reject('ERROR.URL_STARTS_WITH_HTTP');

        // VALIDATE IT DOESN'T END WITH JUST /
        const validateExistingId = new RegExp(/[^/]$/);
        if (!validateExistingId.test(url)) reject('ERROR.MISSING_ID');

        resolve(true);
    });
}
