import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LocalizedText from '../../../components/LocalizedText';
import UnderlinedText from '../../../components/UnderlinedText';
import TopFiveTracksList from './TopFiveTracksList';
import Img from '../../../components/Img';
import { formatNumber } from '../../../services/formatting';
import { StorageDetailsTypes } from '../types';

const Sidebar = () => {
    const storage = localStorage.getItem('artist_details');
    const details: StorageDetailsTypes = storage ? JSON.parse(storage) : {};

    if (!Object.keys(details).length) return null;

    return (
        <React.Fragment>
            <Grid item pb={{ sm: 4, md: 0 }} xs={12} sm={6} md={12} textAlign='center'>
                {details.image_url && (
                    <Img role='profile-img' maxWidth={146} src={details.image_url as string} />
                )}
                {details.artist_name && (
                    <Box my={2}>
                        <UnderlinedText bgColor='skyBlue'>
                            <Typography variant='body1' textTransform='uppercase' fontWeight='bold'>
                                {details.artist_name}
                            </Typography>
                        </UnderlinedText>
                    </Box>
                )}
                {details.monthly_listeners && (
                    <Box>
                        <Typography variant='body1' textTransform='uppercase'>
                            {formatNumber(details.monthly_listeners as number)}{' '}
                            <LocalizedText id='MONTHLY_LISTENERS' />
                        </Typography>
                    </Box>
                )}
                <Box mt={2} sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Button variant='contained' color='green' component={RouterLink} to='/'>
                        <LocalizedText id='NOT_YOU_TRY_AGAIN' />
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={12} textAlign='center'>
                <TopFiveTracksList data={details ? (details.top_tracks as string[]) : []} />
                <Box py={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Button variant='contained' color='green' component={RouterLink} to='/'>
                        <LocalizedText id='NOT_YOU_TRY_AGAIN' />
                    </Button>
                </Box>
            </Grid>
        </React.Fragment>
    );
};

export default Sidebar;
