import { Link, TextField, Typography } from '@mui/material';

import React, { useContext } from 'react';

import { URLValuationFormContext } from '../../../context/URLValuationForm';
import LocalizedText from '../../LocalizedText';
import getLocalizedText from '../../../services/localization';
import { LanguageContext } from '../../../context/Languages';

const InputField = ({ isHeader = false }: { isHeader?: boolean }) => {
    const { dictionary } = useContext(LanguageContext);
    const { input, setInput, error, setError, thresholdNotMet } =
        useContext(URLValuationFormContext);

    if (thresholdNotMet) {
        return (
            <Typography variant='body1'>
                <LocalizedText id='THRESHOLD_NOT_MET' />
            </Typography>
        );
    }

    const placeholderText = 'https://open.spotify.com/artist/xxxxxx';

    return (
        <React.Fragment>
            <TextField
                size='small'
                fullWidth={true}
                label={
                    !isHeader ? getLocalizedText('ENTER_SPOTIFY_URL', dictionary) : placeholderText
                }
                variant='standard'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setInput(event.target.value)
                }
                onFocus={() => setError(undefined)}
                value={input}
                error={error ? true : false}
                helperText={error && error}
                placeholder={placeholderText}
                sx={theme => {
                    return isHeader ? { minWidth: theme.spacing(34) } : null;
                }}
            />
            {!isHeader && (
                <Link
                    href='https://artists.spotify.com/en/help/article/finding-your-artist-url'
                    target='_blank'
                    rel='noreferrer'
                    variant='caption'>
                    <LocalizedText id='WHERE_TO_FIND_SPOTIFY_URL' />
                </Link>
            )}
        </React.Fragment>
    );
};

export default InputField;
