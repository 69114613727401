import LocalizedText from '../../../../components/LocalizedText';
import stepsDataTypes from './types';

const stepsData: stepsDataTypes = [
    {
        label: <LocalizedText id='THREE_STEPS_01_TITLE' />,
        description: <LocalizedText id='THREE_STEPS_01_DESCRIPTION' />,
    },
    {
        label: <LocalizedText id='THREE_STEPS_02_TITLE' />,
        description: <LocalizedText id='THREE_STEPS_02_DESCRIPTION' />,
    },
    {
        label: <LocalizedText id='THREE_STEPS_03_TITLE' />,
        description: <LocalizedText id='THREE_STEPS_03_DESCRIPTION' />,
    },
];

export default stepsData;
