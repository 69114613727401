import { matchIsValidTel } from 'mui-tel-input';
import FormValuesTypes from './types';

/**
 * Check against a regex if email has the appropriate format
 *
 * @param {string | null} email - Email string
 *
 * @return {boolean}
 * */
function validateEmail(email = '') {
    const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return expression.test(String(email).toLowerCase());
}

/**
 * Validate mobile and email fields before submitting
 *
 * @param {FormValuesTypes} formValues - Form inputs value
 *
 * @return {promise} - Resolve/Reject
 * */
export default function validateBeforeSubmit(formValues: FormValuesTypes) {
    return new Promise((resolve, reject) => {
        let errors = {};
        if (!matchIsValidTel(formValues['mobilephone'] as string)) {
            errors = { ...errors, mobilephone: true };
        }

        if (!validateEmail(formValues['email'] as string)) {
            errors = { ...errors, email: true };
        }

        if (Object.values(errors).indexOf(true) > -1) reject(errors);
        resolve(true);
    });
}
