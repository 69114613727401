import { useContext } from 'react';
import * as DOMPurify from 'dompurify';
import { LanguageContext } from '../../context/Languages';
import getLocalizedText from '../../services/localization';

const LocalizedText = ({ id }: { id: string }) => {
    const { dictionary } = useContext(LanguageContext);
    const text = getLocalizedText(id, dictionary);
    const cleanHTML = DOMPurify.sanitize(text);

    return <span dangerouslySetInnerHTML={{ __html: cleanHTML }}></span>;
};

export default LocalizedText;
