import Text from './styledComponents/Text';
import HeroTypes from './types';

const Hero = ({ children, variant, align, bgColor, shadowColor }: HeroTypes) => (
    <Text
        align={align || 'center'}
        variant={variant || 'h4'}
        textTransform='uppercase'
        backgroundcolor={bgColor}
        dropshadowcolor={shadowColor}
        fontWeight='bold'>
        {children}
    </Text>
);

export default Hero;
