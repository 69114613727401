import { styled } from '@mui/material';

const OptionsList = styled('ul')(({ theme }) => ({
    margin: 0,
    padding: 0,
    borderRight: `2px solid ${theme.palette.white.main}`,

    '& li': {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

export default OptionsList;
