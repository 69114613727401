import { Typography } from '@mui/material';
import LocalizedText from '../../../components/LocalizedText';
import Section from '../../../components/Section';
import CenterContentSection from '../../../components/Section/CenterContentSection';
import UnderlinedText from '../../../components/UnderlinedText';

const data = {
    label: (
        <Typography align='center' variant='body1' textTransform='uppercase' fontWeight='bold'>
            <UnderlinedText bgColor='white'>
                <LocalizedText id='ASSUMPTIONS_TITLE' />
            </UnderlinedText>
        </Typography>
    ),
    description: (
        <Typography align='left' variant='body1'>
            <LocalizedText id='ASSUMPTIONS_DESCRIPTION' />
        </Typography>
    ),
};

const Assumptions = () => (
    <Section id='assumptions' backgroundColor='green'>
        <CenterContentSection data={[data]} />
    </Section>
);

export default Assumptions;
