import { styled, Typography } from '@mui/material';
import { RightsyColorsTypes } from '../../../assets/theme';

const Text = styled(Typography)<{
    backgroundcolor: RightsyColorsTypes | undefined;
    dropshadowcolor: RightsyColorsTypes | undefined;
}>(({ theme, backgroundcolor, dropshadowcolor }) => {
    function getShadow() {
        const shadowCut = backgroundcolor
            ? theme.palette[backgroundcolor].main
            : theme.palette.yellow.main;

        const shadow = dropshadowcolor
            ? theme.palette[dropshadowcolor].main
            : theme.palette.green.main;

        return `1px 1px 0px ${shadowCut}, 4px 4px 0px ${shadow}`;
    }

    return {
        textShadow: getShadow(),
        fontWeight: '600',
    };
});

export default Text;
