import { Box } from '@mui/material';

import { VALUATION_URL_INPUT_FIELD_ID } from '../../services/constants';

import SubmitButton from './components/SubmitButton';
import InputField from './components/InputField';

const SectionValuationForm = () => (
    <Box id={VALUATION_URL_INPUT_FIELD_ID}>
        <Box m={{ xs: 2, sm: 6 }} textAlign='left'>
            <InputField />
        </Box>
        <SubmitButton />
    </Box>
);

export default SectionValuationForm;
