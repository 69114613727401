import axios from 'axios';

const getIdFromUrl = (url: string) => {
    let id = url.split('artist/')[1];
    id = id.split('?')[0];
    return id;
};

const fetchArtistDetails = (url: string) => {
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const endpoint = process.env.REACT_APP_FETCH_ARTIST_DETAILS_ENDPOINT as string;

    let id;
    try {
        id = getIdFromUrl(url);
    } catch {
        return Promise.reject('ERROR.WRONG_URL');
    }

    const queryUrl = apiUrl + endpoint + `?spotify_id=${id}`;

    return axios
        .get(queryUrl)
        .then(res => res.data)
        .catch(() => 'ERROR.AXIOS.DETAILS_NOT_FOUND');
};
export default fetchArtistDetails;
