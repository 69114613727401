import { Paper, styled } from '@mui/material';

const StyledHeader = styled(Paper)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 999,
    backgroundColor: theme.palette.yellow.main,
}));

export default StyledHeader;
