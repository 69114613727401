import { Typography } from '@mui/material';

import LocalizedText from '../../../components/LocalizedText';
import Section from '../../../components/Section';
import CenterContentSection from '../../../components/Section/CenterContentSection';
import { CenterContentSectionTypes } from '../../../components/Section/types';
import UnderlinedText from '../../../components/UnderlinedText';

const sectionData: CenterContentSectionTypes = {
    label: (
        <Typography
            position='relative'
            align='center'
            variant='body1'
            textTransform='uppercase'
            fontWeight='bold'>
            <UnderlinedText bgColor='white'>
                <LocalizedText id='WHY_UNLOCK_CASH_SECTION_HEADER' />
            </UnderlinedText>
        </Typography>
    ),
    description: (
        <Typography variant='body1'>
            <LocalizedText id='WHY_UNLOCK_CASH_SECTION_DESCRIPTION' />
        </Typography>
    ),
};

const WhyUnlockCashSection = () => (
    <Section backgroundColor='green'>
        <CenterContentSection data={[sectionData]} />
    </Section>
);

export default WhyUnlockCashSection;
