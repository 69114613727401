import { Box, Container, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../assets/theme';
import { ROUTES } from '../../services/constants';
import LocalizedText from '../LocalizedText';

const links = [
    { to: ROUTES.PRIVACY_POLICY, text: <LocalizedText id='PRIVACY_POLICY' /> },
    { to: ROUTES.TERMS_AND_CONDITIONS, text: <LocalizedText id='TERMS_AND_CONDITIONS' /> },
];

const Footer = () => (
    <Box component='footer' position='relative'>
        <Container maxWidth={false} sx={theme => ({ background: theme.palette.yellow.main })}>
            <Container maxWidth='lg'>
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    height={theme.spacing(8)}>
                    {links.map((link, i) => (
                        <Box key={i} component={'span'} px={2}>
                            <Link
                                component={RouterLink}
                                to={link.to}
                                relative='path'
                                sx={theme => ({
                                    color: theme.palette.primary.main,
                                    textDecoration: 'none',
                                })}>
                                <Typography variant='body1'>{link.text}</Typography>
                            </Link>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Container>
    </Box>
);

export default Footer;
