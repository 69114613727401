import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';
import { languageOptions, dictionaryList } from '../../languages';

function defaultLanguage() {
    const storedLanguage = window.localStorage.getItem('language');
    if (storedLanguage) {
        if (languageOptions[storedLanguage]) {
            return storedLanguage;
        } else {
            console.log('Language [%s] from local storage not supported', storedLanguage);
        }
    }

    let appDefaultLanguage = 'en';
    let userLang = navigator.language || navigator.userLanguage;
    if (userLang) {
        userLang = userLang.split('-')[0].toLowerCase();

        // check if the language is supported
        if (languageOptions[userLang]) {
            if (userLang !== appDefaultLanguage) {
                console.log('Language [%s] from browser is supported, switching', userLang);
            }
            return userLang;
        } else {
            console.log('Language [%s] from browser not supported, using default [%s]', userLang, appDefaultLanguage);
        }
    }

    return appDefaultLanguage;
}

export const LanguageContext = createContext({
    userLanguage: 'en',
    dictionary: dictionaryList.en,
    // eslint-disable-next-line
    userLanguageChange: value => {},
});

export function LanguageProvider({ children }) {
    const [userLanguage, setUserLanguage] = useState(defaultLanguage());

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: selected => {
            const newLanguage = languageOptions[selected] ? selected : 'en';
            setUserLanguage(newLanguage);
            window.localStorage.setItem('language', newLanguage);
        },
    };

    const { search } = useLocation();
    const queryLang = new URLSearchParams(search).get('lng');
    if (queryLang && queryLang !== userLanguage) {
        if (languageOptions[queryLang]) {
            console.log('Switching language to %s', queryLang)
            provider.userLanguageChange(queryLang);
        } else {
            console.log('Requested language %s not supported', queryLang);
        }
    }



    return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
}

LanguageProvider.propTypes = {
    children: PropTypes.node,
};
