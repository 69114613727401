import { Box, Button } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../../context/Languages';
import { URLValuationFormContext } from '../../../context/URLValuationForm';
import LocalizedText from '../../LocalizedText';
import submitData from '../logic';

const SubmitButton = () => {
    const navigate = useNavigate();
    const { dictionary } = useContext(LanguageContext);
    const { input, setError, fetching, toggleFetching, thresholdNotMet, setThresholdNotMet } =
        useContext(URLValuationFormContext);

    function handleSubmit(
        event:
            | React.KeyboardEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) {
        event.preventDefault();
        if (input == '') return;

        toggleFetching();
        submitData(input, dictionary)
            .then(meetThreshold => {
                if (meetThreshold) {
                    navigate('../profile-details', { replace: true });
                } else {
                    setThresholdNotMet(true);
                }
            })
            .catch(errorMessage => setError(errorMessage))
            .finally(() => {
                toggleFetching();
            });
    }

    return (
        <Box>
            {thresholdNotMet ? (
                <Button
                    color='skyBlue'
                    variant='outlined'
                    size='large'
                    onClick={() => setThresholdNotMet(false)}>
                    <LocalizedText id='UNDERSTOOD' />
                </Button>
            ) : (
                <Button
                    color='green'
                    variant='contained'
                    size='large'
                    onClick={handleSubmit}
                    disabled={fetching}>
                    <LocalizedText id='CTA_GET_VALUATION' />
                </Button>
            )}
        </Box>
    );
};
export default SubmitButton;
