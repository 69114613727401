import { styled } from '@mui/material';

const StyledList = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    padding: '0',
    margin: '0',
    '& li': {
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2),
        },
        '&:not(:last-of-type)': {
            borderBottom: '1px solid #ffffff80',
        },
    },
}));

export default StyledList;
