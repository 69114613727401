import { RadioButtonCheckedOutlined } from '@mui/icons-material';
import { styled } from '@mui/material';

const StepIcon = () => {
    const Icon = styled(RadioButtonCheckedOutlined)(({ theme }) => ({
        color: theme.palette.white.main,
    }));
    return <Icon />;
};
export default StepIcon;
