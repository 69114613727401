import { Select, styled } from '@mui/material';

const StyledSelect = styled(Select)(({ theme }) => {
    return {
        '& .MuiSelect-select.MuiSelect-standard': {
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(1),
            },
            paddingBottom: theme.spacing(0),
            minHeight: 'auto',
        },
    };
});

export default StyledSelect;
