import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Homepage from '../../pages/Homepage';
import PrivacyPolicy from '../../pages/Legal/PrivacyPolicy';
import TCs from '../../pages/Legal/TCs';
import NotFound from '../../pages/NotFound';
import ProfileDetails from '../../pages/ProfileVerification';
import Valuation from '../../pages/Valuation';
import { ROUTES } from '../../services/constants';
import { smoothScroll, scrollToTop } from './hooks';

const Routes = () => {
    smoothScroll();
    scrollToTop();
    return (
        <RouterRoutes>
            <Route key={ROUTES.HOMEPAGE} path={ROUTES.HOMEPAGE} element={<Homepage />} />
            <Route
                key={ROUTES.PROFILE_DETAILS}
                path={ROUTES.PROFILE_DETAILS}
                element={<ProfileDetails />}
            />
            <Route key={ROUTES.VALUATION_SHORT} path={ROUTES.VALUATION_SHORT} element={<Valuation />} />
            <Route key={ROUTES.VALUATION} path={ROUTES.VALUATION} element={<Valuation />} />
            <Route
                key={ROUTES.TERMS_AND_CONDITIONS}
                path={ROUTES.TERMS_AND_CONDITIONS}
                element={<TCs />}
            />
            <Route
                key={ROUTES.PRIVACY_POLICY}
                path={ROUTES.PRIVACY_POLICY}
                element={<PrivacyPolicy />}
            />
            <Route key={ROUTES.NOT_FOUND} path={ROUTES.NOT_FOUND} element={<NotFound />} />
        </RouterRoutes>
    );
};

export default Routes;
